:root {
    --noir: #111111;
    --anciennoir: #343434;
    --jaune: #F7DE3A;
    --orange: #EE6123;
    --orangeClair: #F49D6E;
    --blanc: #ffffff;
    --gris: #f6f7f9;
    --jauneClair : #FFFEE3;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}


.App {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    min-height: 100vh;
}

main {
    padding-top: 1em;
    background-color: var(--gris);
    background-image: -webkit-linear-gradient(40deg, var(--gris) 75%, var(--jaune) 25%);
}

footer {
    background-color: var(--gris);
}

#divFooter {
    display: flex;
    justify-content: center;
    padding: 128px 24px 16px 16px;
    box-sizing: border-box;
}

#footer {
    background-color: var(--gris);
    color: #4d4c4c;
    border-top: 3px solid var(--jaune);
    padding: 0 16px;
    box-sizing: border-box;
}



#brand {
    font-weight: bold;
    padding-top: 0;
    border-top: 0.2em solid var(--jaune);
    border-bottom: 0.2em solid var(--jaune);
}

#logoUAC {
    color: var(--jaune)
}

#logoPerformance {
    color: var(--blanc)
}

#navbarMenu {
    /*background: linear-gradient(90deg, var(--orange), var(--jaune)) !important;*/
    background: var(--noir) !important;

}


.navLinkMenu {
    color: var(--blanc) !important;
    font-weight: bold !important;
}

.navLinkMenu:hover {
    color: var(--jaune) !important;
    font-weight: bold !important;
    text-decoration: overline var(--jaune);
    text-decoration-thickness: 0.1em;
}

.navLinkDropDown {
    background-color: red;
}

.navbar-toggler {
    color: var(--jaune) !important;
    background-color: var(--noir) !important;
}

.navbar-toggler-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-image: none !important;
    font-size: 1.2em !important;
}

.navbar-toggler-icon::before {
    font-family: FontAwesome;
    content: "\f0c9";
    /* fa-bars, fa-navicon */
}

@media (max-width: 992px) {
    #basic-navbar-nav {
        display: none;
    }
}


#offcanvasNavbar {
    background-color: var(--noir);
    color: var(--blanc);
    font-size: x-large;
    text-align: center;
}

.buttonMenu {
    color: var(--blanc);
    width: 100%;
    border-bottom: 1px solid white;

}

.buttonMenuDouble{
    border: 1px solid var(--jaune);
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.titleOffCanvas{
    color: var(--noir);
    background-color: var(--jaune);
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#containerMenuOffcanvas{
    height: 100%;
    overflowY: auto;
}

.iconClose{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-image: none !important;
    font-size: 1.7em !important;
    color: var(--jaune);
}

.modalBody{
    background-color: var(--jaune);
}

.headerModal {
    background-color: var(--noir);
    color: var(--jaune);
}

.titleModal{
    font-weight: bold !important;
}

.linkModal {
    color: var(--noir) !important;
    border: var(--noir) solid 2px;
    margin-right: 0.4em;
    margin-bottom: 0.4em;
    background-color: var(--blanc);
    padding: 1em;
    font-weight: bold !important;
    border-radius: 0.5em;
}


.titleHomePage {
    font-weight: bold;
    color: var(--noir);
}

h1 {
    font-size: 1.8rem !important;
}

h2 {
    font-size: 1.5rem !important;
}

h3 {
    font-size: 1.2rem !important;
}

p{
    font-size: 1rem!important;
}

.cardTitle{
    font-size: 1.2rem !important;
}

.cardText{
    font-size: 0.9rem !important;
}

.titleh1 {
    font-weight: bold;
    color: var(--noir);
}

.titleh2 {
    font-weight: bold;
    color: var(--noir);
    font-size: 1.2em
}

.championship-row{
    font-weight: bold;
    background-color: var(--jaune) !important;
}

.tablePerf {
    font-size: 0.8rem;
}

.titreTop {
    font-weight: bold;
    text-align: center;
}

.performanceDansTop {
    float: right !important;
}

.listeTop .listeTop-item:nth-child(1) {
    background-color: var(--jaune);
}

.listeTop .listeTop-item:nth-child(2) {
    background-color: #C0C0C0;
}

.listeTop .listeTop-item:nth-child(3) {
    background-color: #c49c48;
}

.listeTop .listeTop-item:nth-child(n+11) {
    background-color: lightgray;
    color: var(--noir);
}

.ponctuationForm {
    font-weight: bold;
    font-size: 1.5rem;
}

.kmh {
    font-size: 0.7rem;
    color: #4d4c4c;
}

.cardHomePage {
    background-color: var(--gris) !important;
}

#imageUAC {
    max-width: 50%;
    height: auto;
}

.jaune {
    border-bottom: 0.2em solid var(--jaune);
}

.jauneTitre {
    border-bottom: 0.2em solid var(--jaune);
}

@media (max-width: 992px) {
    .jauneTitre {
        border-bottom: 0.2em solid var(--noir);
    }
}

.buttonJaune {
    background-color: var(--jaune) !important;
    color: var(--noir) !important;
    border: var(--jaune) solid 2px !important;
}

.buttonJaune:hover {
    background-color: var(--anciennoir) !important;
    color: var(--jaune) !important;
    border: var(--jaune) solid 2px !important;
}

.buttonJauneExterne {
    background-color: var(--gris) !important;
    color: var(--noir) !important;
    border: var(--jaune) solid 2px !important;
}

.buttonJauneExterne:hover {
    background-color: var(--noir) !important;
    color: var(--gris) !important;
    border: var(--jaune) solid 2px !important;
}

.bloc {
    background-color: var(--blanc);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, .3), 0px 1px 3px 1px rgba(60, 64, 67, .15);
    padding: 1.5em;
    margin-bottom: 2em;
}

#listGroupFiche {
    background-color: var(--blanc);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, .3), 0px 1px 3px 1px rgba(60, 64, 67, .15);
}

#NavGroup .nav-link.active {
    background-color: var(--jaune) !important;
    color: var(--noir) !important;
    font-weight: bold !important;
}

#NavGroup .nav-link {
    color: var(--noir) !important;
    border: var(--jaune) solid 2px;
    margin-right: 0.4em;
    margin-bottom: 0.4em;
}

#tabGroupe td {
    background-color: var(--gris);
    box-shadow: none;
}

.titleBloc {
    font-weight: 600;
}

#cardTop card {
    background-color: white;
}

.icon {
    height: 1em;
    width: 1em;
    padding-right: 0.3em;
}

.returnButton {
    background-color: var(--blanc);
    padding: 0.6em;
    border-radius: 0.2em;
    color: var(--anciennoir);
    border: var(--anciennoir) 1px solid;
}

.returnButton:hover {
    background-color: var(--jaune);
    padding: 0.6em;
    border-radius: 0.2em;
    color: var(--noir);
    font-weight: bold;
    border: 0px;
}

@media (max-width: 767.98px) {
    .returnButton {
        font-size: 1.2rem;
        background-color: var(--gris);
        border: 0px;
    }
}

.icon {
    width: 0.8em;
    height: 0.8em;
    margin-left: 0.2em;
    margin-right: 0.6em;
    color: var(--noir);
    /*border-radius: 50%;
    background-color: var(--noir);
    stroke: var(--jaune);
    stroke-width: 10px;*/
}

.iconTransparence {
    width: 0.7em;
}

.lienJaune {
    border-bottom: 0.15em solid var(--jaune);
}


.monthGroup{
    background-color: var(--jaune);
}

.monthGroupText{
    font-weight: bold;
    padding-left: 2em;
}


.offcanvasNav{
    border-bottom: var(--jaune) 3px solid !important;
}

.cardTeam{
    flex-direction: row !important;
}

.cardTeamBody{
   padding: 0.5em!important;
}

.cardImgTeam {
    max-width: 4em;
    min-width: 2em;
    height: 5em;
    object-fit: cover;
  }

.titleTeamMember{
    font-weight: bold!important;
    font-size: .9375em!important;
}

.bodyTeam{
    font-size: 0.75em!important;
    min-height: 16px;
}

.mailMember{
    color: gray;
}

.fieldTitleNews{
    width: 100%;
}

.fieldContentNews{
    width: 100%;
    min-height: 14em;
    resize: none
}


.iconPDF{
    margin-right: 1em;
}

.imgNews{
    max-height: 50em;
    width: auto!important;
    display: block; /* permet de régler les marges automatiques */
    margin: 0 auto; /* centrer horizontalement l'image */
}

.buttonNews{
    max-width: 30em!important;
    display: block; /* permet de régler les marges automatiques */
    margin: 0 auto; /* centrer horizontalement l'image */
}


.spaceIconeRight{
    margin-right: 10px;
}


.listInfra{
    list-style-type: "\2B50";
    font-size: 1em;
    margin-top: 1em;
}

.listInfra li{
    margin-top: 0.6em;
    padding-left: 0.5em;
}

.labelCoachForm{
    margin-right: 10px;
}

.titleForm{
    text-decoration: underline;
    font-weight: bold;
    margin-top: 0.8em;
    margin-bottom: 0.4em;
}


.cardImgNews{
    width: 100%;
    object-fit: cover;
    height: 25vw;
}

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .cardImgNews {
        height: 25vw;
    }
  }
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .cardImgNews {
        height: 16vw;
    }
  }
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .cardImgNews {
        height: 7vw;
    }
  }

  .linkRGPD{
    text-decoration: underline;
    font-size: smaller;
    color: #4d4c4c;
  }


.rgpdConsent{
    vertical-align: middle;
}
.rgpdConsentLabel{
    margin-left: 5px;
    vertical-align: middle;

}

.center-table {
    text-align: center;
}

.center-table td{
    vertical-align: middle;
}

.carousel-image {
    max-height: 300px; /* Réglez la hauteur maximale souhaitée */
    margin: 0 auto; /* Pour centrer horizontalement */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }


  .carousel-inner {
    height: 0;
    padding-bottom: 50%; /* this sets carousel aspect ratio (4:1 here) */
  }

  .carousel-item {
    position: absolute !important; /* Bootstrap is insistent */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .carousel-item img {
    height: 100%; /* Bootstrap handles width already */
    object-fit: cover; /* or 'contain' if you want stretch instead of crop */
  }



  .seasonButton {
    background-color: var(--blanc) !important;
    padding: 0.6em !important;
    border-radius: 0.2em !important;
    color: var(--anciennoir) !important;
    border: var(--anciennoir) 1px solid !important;
}

.seasonButton:hover, .selectedSeasonButton {
    background-color: var(--jaune) !important;
    padding: 0.6em !important;
    border-radius: 0.2em !important;
    color: var(--noir) !important;
    font-weight: bold !important;
    border: 0px !important;
}




.cardYellow{
    background-color: var(--jaune) !important;
}

.fieldset{
    border: var(--jaune) 5px solid;
    padding: 1em;
}

/*   INSTA  */

.instagram-media > div {
    padding: 16px;
}

#main_link > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#main_link > div {
    padding: 19% 0;
}

#main_link > div {
    display: block;
    height: 50px;
    margin: 0 auto 12px;
    width: 50px;
}

#main_link > div {
    padding-top: 8px;
}

#main_link > div {
    padding: 12.5% 0;
}

#main_link > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    align-items: center;
}

#main_link > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 24px;
}

#main_link {
    background: #FFFFFF;
    line-height: 0;
    padding: 0 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.instagram-media {
    background: #FFF;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    /*width: undefinedpx;*/
    height: undefinedpx;
    max-height: 100%;
    /*width: undefinedpx;*/
}

.windspeedNOK{
    color: darkred ;
    opacity: 0.7;
}

.windspeedPositif{
    color : grey;
    opacity: 0.7;
}

.windspeedNegatif{
    color : forestgreen;
    opacity: 0.7;
}