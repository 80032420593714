


.containerForm{
    background-color: white;
    border: 0.3em solid grey;
    border-radius: 1.5em;
}

.containerAttestation{
    background-color: white;
    border: 0.3em black dashed;
    font-family: "Times New Roman";
    padding-right: 2em;
    padding-left: 2em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.borderSaisonDate{
    border: 0.1em black solid;
}

.inputPdf{
    font-size: 1.2em;
    font-weight: bold;
}

.paragraphPdf{
    text-align: justify;
}

.sigCanvas{
    background-color: white;
    border : 2px solid black;
}