@media (min-width: 992px) {
  .search {
      margin-right: 2em;
  }
}

@media (max-width: 992px) {
  .searchInputs{
    border: black solid 2px;
    border-radius: 0.5em;
  }

  .search input {
    padding: 1em !important;
    max-width: 50em !important;
    font-weight: bold;
  }

  .dataResult {
    padding: 1em !important;
    max-width: 18em !important;
    font-weight: bold;

  }

  .dataResult .dataItem {
    border-bottom: 1px solid black ;
  } 
}


.searchInputs{
    display: flex;
}


.search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0.7em;
    width: 100%;
    max-width: 14em;
    min-width: 6em!important;
}

.searchIcon{
    display: grid;
    place-items: center;
    color : gray;
    background-color: white;
    padding: 0;
}

.search .icon{
    color : black;
    width: 1.2em;
    height: 1.2em;
}

input:focus {
    outline: none;
}

.dataResult {
    margin-top: 0.313em;
    width: 100%;
    max-width: 14em;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0em 0.313em 0.938em;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 3.125em;
    display: flex;
    align-items: center;
    color: black;
    padding-left: 1em;
  }
  
  .dataItem p {
    margin-left: 0.625em;
  }
  .dataResult  a {
    text-decoration: none;
  }
  
  .dataResult  a:hover {
    background-color: lightgrey;
  }
  
  #clearBtn {
    cursor: pointer;
  }